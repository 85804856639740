/**
 * External dependencies.
 */
import React, { useRef, useEffect } from "react";
import Script from "next/script";
import type { AppProps } from "next/app";
import App from "next/app";
import { useRouter } from "next/router";
import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
// import AOS from "aos";
import { QueryClient, QueryClientProvider } from "react-query";

/**
 * Internal dependencies.
 */
import { initTranslation, fetchAPI, getLocale } from "../utils";
import GlobalContext from "contexts/global";
import { SearchProvider } from "contexts/search-context";
import Bugsnag from "../lib/bugsnag";
import * as gtag from "../lib/gtag";
// import * as fbq from "../lib/fpixel";
import "aos/dist/aos.css";
import "../assets/scss/main.scss";
import LangProvider from "contexts/lang-context";
import Head from "next/head";

// @ts-ignore
const ErrorBoundary = process.env.NEXT_PUBLIC_DEBUG
  ? ({ children }: any) => <>{children}</>
  : // @ts-ignore
    Bugsnag.getPlugin("react").createErrorBoundary(React);

const queryClient = new QueryClient();

//initialization function
initTranslation(i18n);

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const locale: any = router.locale || router.defaultLocale;
  const firstRender = useRef(true);

  if (pageProps.translation && firstRender.current) {
    //load the translations for the locale
    i18n.load(locale, pageProps.translation);
    i18n.activate(locale);
    // render only once
    firstRender.current = false;
  }

  const handleRouteChange = (url: any) => {
    gtag.pageview(url);
    // fbq.pageview();
  };

  useEffect(() => {
    // fbq.pageview();

    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  // useEffect(() => {
  //   AOS.init({
  //     easing: "ease-out-cubic",
  //     once: true,
  //     offset: 50,
  //   });
  // }, []);

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,maximum-scale=1"
        />
      </Head>
      <ErrorBoundary>
        <Script
          strategy="afterInteractive"
          src={`https://insights.digifist.com/gtag/js?id=L9QNV4PFVF`}
        />
        <Script
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-L9QNV4PFVF', {
              page_path: window.location.pathname,
            });
          `,
          }}
        />
        <Script strategy="afterInteractive">
          {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://insights.digifist.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-5RD5TGN');
        `}
        </Script>
        <QueryClientProvider client={queryClient}>
          <GlobalContext.Provider value={pageProps}>
            <SearchProvider>
              <LangProvider>
                <I18nProvider i18n={i18n}>
                  <Component {...pageProps} />
                </I18nProvider>
              </LangProvider>
            </SearchProvider>
          </GlobalContext.Provider>
        </QueryClientProvider>
      </ErrorBoundary>
    </>
  );
}

MyApp.getInitialProps = async (ctx: any) => {
  const appProps = await App.getInitialProps(ctx);
  const globalRes = await fetchAPI("/global", {
    populate: {
      HeaderLeftMenu: "*",
      HeaderRightMenu: "*",
      Home: {
        populate: "*",
      },
      Favico: "*",
      FooterLinks: {
        populate: "*",
      },
      SocialLinks: "*",
      BottomLinks: "*",
    },
    locale: getLocale(ctx.ctx.locale),
  });
  const strapiLocales = await fetchAPI("/i18n/locales", {
    populate: "*",
  });

  return {
    ...appProps,
    pageProps: { global: globalRes?.data?.attributes ?? {}, strapiLocales },
  };
};

export default MyApp;
